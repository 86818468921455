// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-onlinepci-pink: (
  50: #feeff5,
  100: #fcd7e6,
  200: #fbbdd5,
  300: #f9a2c4,
  400: #f78eb7,
  500: #f67aaa,
  600: #f572a3,
  700: #f36799,
  800: #f25d90,
  900: #ef4a7f,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffe1ea,
  A700: #ffc8d9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$mat-onlinepci-yellow: (
  50: #fefbe6,
  100: #fdf4c0,
  200: #fbed96,
  300: #f9e56c,
  400: #f8e04d,
  500: #f7da2d,
  600: #f6d628,
  700: #f5d022,
  800: #f3cb1c,
  900: #f1c211,
  A100: #ffffff,
  A200: #fffaeb,
  A400: #ffefb8,
  A700: #ffe99e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-onlinepci-green: (
  50: #f1f8e7,
  100: #dbeec2,
  200: #c3e39a,
  300: #abd771,
  400: #99cf52,
  500: #87c634,
  600: #7fc02f,
  700: #74b927,
  800: #6ab121,
  900: #57a415,
  A100: #e8ffd7,
  A200: #cbffa4,
  A400: #aeff71,
  A700: #9fff58,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-onlinepci-blue: (
  50: #eaf6fa,
  100: #c9e8f2,
  200: #a6d9ea,
  300: #82c9e1,
  400: #67beda,
  500: #4cb2d4,
  600: #45abcf,
  700: #3ca2c9,
  800: #3399c3,
  900: #248ab9,
  A100: #f5fcff,
  A200: #c2eaff,
  A400: #8fd9ff,
  A700: #75d1ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

.round-button {
  border-radius: 50px !important;
  color: white !important;
  font-size: 80%;
  font-family: Arial, Helvetica, sans-serif;
}

loading-button {
  border-radius: 50px !important;
  color: white !important;
  font-size: 90%;
  font-family: Arial, Helvetica, sans-serif;
  // color: white !important;
  // background-color: white !important;
}

h1 {
  letter-spacing: 0em !important;
  margin: 0 !important;
  height: auto !important;
  font-size: 1.5rem !important;
  text-align: center !important;
}

$remote-medic-web-primary: mat-palette($mat-onlinepci-blue);
$remote-medic-web-accent: mat-palette($mat-onlinepci-blue, A200, A100, A400);
$remote-medic-web-note: mat-palette($mat-onlinepci-pink);

// The warn palette is optional (defaults to red).
$remote-medic-web-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$remote-medic-web-theme: mat-light-theme(
  (
    color: (
      primary: $remote-medic-web-primary,
      accent: $remote-medic-web-accent,
      warn: $remote-medic-web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($remote-medic-web-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Noto Sans JP', 'Helvetica Neue', sans-serif;
}
.mat-form-field-required-marker {
  color: red;
}

.patient-info-link-global {
  color: #2196f3;
}

.patient-search-form-container {
  padding: 0%;
  margin-bottom: 0%;

  .search-by-name {
    font-size: 12px;
  }
}

.top-area {
  padding: 0%;
  margin-bottom: 0%;
  .input-area {
    mat-form-field {
      width: 100%;
    }
  }
  .search-by-name {
    font-size: 12px;
  }
}

@media print {
  .hidden-on-print {
    display: none;
  }
}

.mat-snack-bar-container {
  color: black;
  background: #eee;
}

.paginator-and-table {
  .table {
    .header {
      background-color: #4cb2d4 !important;
      color: white !important;
      font-size: 20px !important;
      padding: 0 !important;
      .header-content {
        padding: 0px 0 0px 16px !important;
      }
    }
    .element {
      font-size: 20px !important;
      padding: 0 !important;
      &:hover {
        background-color: #82c9e1 !important;
      }
      .element-content {
        padding: 0px 0 0px 16px;
        .progress-bar-wrap {
          margin-right: 16px;
        }
      }
    }
    .element-content {
      font-size: 20px !important;
      padding: 0 !important;
    }
  }
}

table {
  // width: 100%;
  // margin-top: 20px;
  .clickable-row {
    transition: 0.3s all !important;
    cursor: pointer !important;
    &:hover {
      background-color: #82c9e1 !important;
    }
  }
  th {
    background-color: #4cb2d4 !important;
    color: white !important;
    font-size: 20px !important;
  }
  .clickable {
    &:hover {
      background-color: #82c9e1 !important;
    }
  }
}

tr.mat-header-row,
tr.mat-row {
  height: 40px !important;
  font-size: 40px !important;
}

.mat-cell {
  font-size: 20px !important;
}

.search-label {
  font-size: 15px !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important;
  background-color: #4cb2d4 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #4cb2d4 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4cb2d4 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #4cb2d4 !important;
}

.patient-info-icon {
  vertical-align: middle !important;
}

.mat-raised-button.mat-note {
  background-color: #f36799 !important;
}

.mat-button.mat-note {
  color: #f36799 !important;
}
